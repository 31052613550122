.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#centralizedInput::placeholder {
  text-align: center;
}

.item-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.item-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}

.item-exit {
  opacity: 1;
  transform: translateY(0);
}

.item-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 500ms, transform 500ms;
}

.slide-appear {
  opacity: 0;
  transform: translateY(-20px);
}

.slide-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}

.miniP {
  font-size: 1.3rem;
  line-height: 120%;
  font-style: italic;
  margin-top: 10px;
  color: rgb(197, 197, 197);
}

.low-margin {
  margin-bottom: -30px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.for-small-button {
  max-width: 300px;
}

.dropdown > a {
  min-width: 280px;
}

.dropdown > a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.is_dark .dropdown > a {
  background: #06063b;
}

.dropdown > ul {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: auto;
  border-radius: 0 0 4px 4px;
  z-index: 999;
  -moz-box-shadow: 0px 5px 4px rgba(28, 24, 24, 0.25);
  -webkit-box-shadow: 0px 5px 4px rgba(28, 24, 24, 0.25);
  box-shadow: 0px 5px 4px rgba(28, 24, 24, 0.25);
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
}

.dropdown li {
  display: block;
  font-size: 13px;
  line-height: 20px;
  color: var(--primary-color4);
  font-weight: 400;
}

.is_dark .dropdown li {
  background: #06063b;
}

.rememberToRegister {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  line-height: 120%;
  font-style: italic;
  color: rgb(197, 197, 197);
  margin-top: -50px;
}

.clickable {
  cursor: pointer;
}
