nav#main-nav .menu-item.disabled > a {
  color: gray !important;
  pointer-events: none !important;
}

nav#main-nav .menu-item.disabled:hover > a {
  cursor: not-allowed !important;
}

.header_1 {
  background-color: #191526;
}

.network {
  background-color: #113;
  text-align: center;
  height: 14px;
  position: absolute;
  width: 100%;
  color: #aaa;
  padding-top: 1px;

  transition: all 0.5s ease;
}

.wrong {
  background-color: #f00;
  color: #fff;

  animation: fade-in 5s ease;
}

.right {
  background-color: #0f0;
  color: #000;
  opacity: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
